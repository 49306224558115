<template>
  <v-container fluid id="main-edit">
    <div class="display">
      <v-row>
        <v-col cols="4">
          <v-text-field
            v-model="search"
            :label="$t('feedback.search')"
            outlined
            dense
            persistent-placeholder
            :color="!search ? 'red' : 'blue'"
          />
        </v-col>
        <v-col cols="3">
          <v-autocomplete
            v-model="filterRequestType"
            outlined
            dense
            :label="$t('feedback.requestDetail')"
            persistent-placeholder
            :items="optRequestDetails"
          />
        </v-col>
        <v-col cols="2">
          <v-autocomplete
            v-model="filterReplyStatus"
            outlined
            dense
            :label="$t('feedback.replyStatus')"
            persistent-placeholder
            :items="optReplyStatus"
          />
        </v-col>
        <v-col cols="1">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="addFeedback()" color="primary" dark v-bind="attrs" v-on="on">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>{{ language == "en" ? "Add Feeback" : "フィードバックの追加" }}</span>
          </v-tooltip>
        </v-col>
        <v-col cols="12" v-show="feedBackData.length == 0" v-if="!loadingFeedback">
          <v-card flat>
            <v-card-title class="text-align justify-center pt-15" style="color: red"
              ><h1>
                {{
                  language == "en"
                    ? "No records found!!!"
                    : "レコードが見つかりません！！！"
                }}
              </h1></v-card-title
            >
          </v-card>
        </v-col>
        <!-- NOTE Display FeedBackData -->
        <v-col cols="4" v-for="item in paginatedFeedback" :key="item.comment">
          <v-card elevation="10">
            <div class="bold">
              <b>{{ $t("feedback.feedbackNo") }}.{{ item.FeedbackNo }}</b>
            </div>
            <div class="details">
              <p class="mt-1 mb-2">
                <b>{{ item.RequestType }}</b>
              </p>
              <p class="mt-1 mb-2">
                <b>{{ item.RequestDetails }}</b>
              </p>
              <p class="mt-1 mb-2" v-if="item.Attachments">
                {{ $t("feedback.attachment") }} :
                <v-btn
                  @click="dowloadFeeback(item)"
                  style="cursor: pointer"
                  x-small
                  color="info"
                >
                  {{ item.Attachments.split("_")[1] }}
                </v-btn>
              </p>
              <p class="mt-1 mb-2" v-else>
                {{ $t("feedback.attachment") }} :
                <span class="NA"><b>N/A</b></span>
              </p>
              <p class="mt-1 mb-2">
                {{ $t("feedback.feedbackBy") }} :{{ item.FeedbackBy }} ({{
                  changeDateFormat(item.FeedbackDate)
                }})
              </p>
              <!-- <p>
              <v-btn>Image Preview<v-icon>mdi-eye</v-icon></v-btn>
            </p> -->
              <!-- <p>
              <v-btn
                color="primary"
                dense
                small
                @click="item.isPreview = !item.isPreview"
                :disabled="item.Attachments ? false : true"
              >
                Image Preview
                <v-icon v-if="!item.isPreview">mdi-eye</v-icon>
                <v-icon v-else>mdi-eye-off</v-icon>
              </v-btn>
            </p>
            <template v-for="(record, r) in item.src">
              <v-layout row wrap :key="r">
                <span v-if="item.isPreview">{{ item.attachments[r] }}</span>
                <img v-if="item.isPreview" :src="record" width="350" />
              </v-layout>
            </template> -->
            </div>
            <v-divider></v-divider>
            <div class="bold">
              <b>{{ language == "en" ? "Reply Details" : "返信の詳細" }}</b>
            </div>
            <div class="details">
              <p class="mt-1 mb-2">
                {{ $t("feedback.reply") }} :
                <span v-if="item.ReplyDetails">
                  {{ item.ReplyDetails }} ({{ changeDateFormat(item.ReplyDetailsDate) }})
                </span>
                <span class="NA" v-else>N/A</span>
              </p>
              <p v-if="item.ReplyAttachments" class="mt-1 mb-2">
                {{ $t("feedback.attachment") }} :
                <v-btn
                  style="cursor: pointer"
                  x-small
                  color="info"
                  @click="dowloadReplyAttachment(item)"
                >
                  {{ item.ReplyAttachments.split("_")[1] }}
                </v-btn>
              </p>
              <p v-else class="mt-1 mb-2">
                {{ $t("feedback.attachment") }} :
                <span class="NA"><b>N/A</b></span>
              </p>
            </div>

            <v-divider></v-divider>
            <div class="actions">
              <div>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      @click="ResendFeedback(item)"
                      v-if="item.FeedbackBy == userinfo.FullName"
                      color="primary"
                      >mdi-reload
                    </v-icon>
                  </template>
                  <span>{{
                    language == "en" ? "Resend Feedback" : "フィードバックの再送"
                  }}</span>
                </v-tooltip>
              </div>

              <div>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      @click="openDiscussion(item)"
                      v-if="userinfo.UserLevel == 'Admin'"
                      >mdi-forum</v-icon
                    >
                  </template>
                  <span>{{
                    language == "en" ? "Reply Feedback" : "返信フィードバック"
                  }}</span>
                </v-tooltip>
              </div>

              <div>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      @click="deleteFeedback(item)"
                      v-if="
                        item.FeedbackBy == userinfo.FullName ||
                        userinfo.UserLevel == 'Admin'
                      "
                      color="error"
                      >mdi-delete</v-icon
                    >
                  </template>
                  <span>{{
                    language == "en" ? "Delete Feedback" : "フィードバックの削除"
                  }}</span>
                </v-tooltip>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <!-- NOTE speedDial -->
      <v-speed-dial
        v-model="fab1"
        bottom
        right
        v-if="!loadingFeedback && feedBackData.length != 0"
      >
        <template v-slot:activator>
          <center>
            <v-pagination
              class="pt-10"
              v-model="pageNumber"
              :length="paginationLength"
              :total-visible="5"
              dark
              prev-icon="mdi-menu-left"
              next-icon="mdi-menu-right"
            ></v-pagination>
          </center>
        </template>
      </v-speed-dial>
    </div>
    <!-- NOTE Dialog  feedbackDialog-->
    <v-dialog v-model="feedbackDialog" max-width="900" persistent>
      <v-card>
        <v-toolbar dense flat color="#1565c0" dark>
          <v-toolbar-title
            ><v-icon class="mr-1">mdi-help-circle-outline</v-icon
            >{{ language == "en" ? "Feedback" : "フィードバック" }}</v-toolbar-title
          >
          <v-spacer />
          <v-icon @click="closeFeedbackDialog()">mdi-close</v-icon>
        </v-toolbar>
        <v-card-text class="pa-2">
          <v-row no-gutters class="mt-3">
            <v-col cols="3">
              <v-text-field
                v-model="item.feedBackNo"
                :label="$t('feedback.feedbackNo')"
                outlined
                dense
                class="mr-2"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="9">
              <v-select
                v-model="item.requestType"
                outlined
                dense
                :label="$t('feedback.requestType')"
                :items="optRequestDetails"
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="item.requestDetails"
                :label="$t('feedback.requestDetail')"
                outlined
                dense
              ></v-textarea>
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-if="!hide"
                v-model="ExistFileFeedBack"
                label="Existing File"
                outlined
                dense
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-if="!hide"
                v-model="ExistAttachmentsPath"
                label="AttachmentsPath"
                outlined
                dense
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <span>Total Size: {{ totalSize }}MB / 10MB</span>
              <!-- v-model="tempFile"
                :onaddfile="handleFileUploads"
                :onremovefile="removeFileUpload" -->
              <file-pond
                v-model="tempFile"
                :onaddfile="handleFileUploads"
                :onremovefile="removeFileUpload"
                ref="pond"
                :label-idle="$t('feedback.attachment')"
                allow-multiple="true"
                dropOnPage="true"
              />
              <!-- <v-file-input
                v-model="attachments"
                @change="previewFile2()"
                multiple
                :label="$t('feedback.attachment')"
                outlined
                prepend-icon="mdi-paperclip"
                dense
              >
                <template v-slot:selection="{ text }">
                  <v-chip
                    small
                    label
                    color="primary"
                    close
                    @click:close="removeFile(text)"
                  >
                    {{ text }}
                  </v-chip>
                </template>
              </v-file-input> -->
            </v-col>
            <input type="hidden" name="id" value="id" />
            <v-col cols="12" v-if="images.length > 0">
              <p>{{ language == "en" ? "Preview Image" : "イメージのプレビュー" }}:</p>
              <template v-for="(item, i) in images">
                <v-layout row wrap :key="i" class="ma-1 pa-1">
                  <span>{{ item.name }}</span>
                  <img :src="item.src" width="500" />
                </v-layout>
              </template>
            </v-col>
          </v-row>
          <v-layout class="ma-1 pa-1" dense v-if="!isEdit">
            <v-spacer></v-spacer>
            <v-btn color="#1565c0" dark @click="SaveFeedback()">
              <v-icon class="mr-1">mdi-send</v-icon
              >{{ language == "en" ? "Send" : "送信" }}
            </v-btn>
          </v-layout>
          <v-layout class="ma-1 pa-1" dense v-else>
            <v-spacer></v-spacer>
            <v-btn color="#1565c0" dark @click="UpdateFeedback()">
              <v-icon class="mr-1">mdi-send</v-icon>
              {{ language == "en" ? "resend" : "再送する" }}
            </v-btn>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- NOTE Dialog replyDialog-->
    <v-dialog v-model="replyDialog" max-width="600" persistent>
      <v-card>
        <v-toolbar dense flat color="#1565c0" dark>
          <v-toolbar-title
            ><v-icon class="mr-1">mdi-reply</v-icon
            >{{ $t("feedback.reply") }}</v-toolbar-title
          >
          <v-spacer />
          <v-icon @click="replyDialog = false">mdi-close</v-icon>
        </v-toolbar>
        <!-- <v-layout class="ma-1"> -->
        <v-text-field
          v-if="!hide"
          v-model="ExistFileReply"
          dense
          outlined
          label="Exist ReplyFile"
          class="pl-5 pt-3"
          readonly
          style="width: 50%"
        >
        </v-text-field>
        <v-text-field
          v-if="!hide"
          v-model="item.feedBackNo"
          :label="$t('feedback.feedbackNo')"
          outlined
          dense
          class="mr-2"
          readonly
        ></v-text-field>
        <v-select
          v-if="!hide"
          v-model="item.requestType"
          outlined
          dense
          :label="$t('feedback.requestType')"
          :items="optRequestDetails"
        ></v-select>

        <v-textarea
          v-if="!hide"
          v-model="item.requestDetails"
          :label="$t('feedback.requestDetail')"
          outlined
          dense
        ></v-textarea>
        <!-- <v-text-field
          v-if="!hide"
          v-model="attachments"
          dense
          outlined
          label="Exist ReplyFile"
          class="pl-5 pt-3"
          readonly
          style="width: 50%"
        >
        </v-text-field> -->
        <v-text-field
          v-if="!hide"
          v-model="ExistReplyAttachmentsPath"
          dense
          outlined
          label="Exist ReplyFile"
          class="pl-5 pt-3"
          readonly
          style="width: 50%"
        >
        </v-text-field>
        <v-text-field
          v-if="!hide"
          v-model="EmailFeedbackBy"
          dense
          outlined
          label="EmailFeedback"
          class="pl-5 pt-3"
          readonly
          style="width: 50%"
        >
        </v-text-field>
        <!-- </v-layout> -->
        <v-layout class="ma-2">
          <v-file-input
            @change="previewReplyFile()"
            multiple
            v-model="replyAttachments"
            :label="$t('feedback.attachment')"
            outlined
            prepend-icon="mdi-paperclip"
            dense
            hide-details
          >
            <template v-slot:selection="{ text }">
              <v-chip
                small
                label
                color="primary"
                close
                @click:close="removeReplyFile(text)"
              >
                {{ text }}
              </v-chip>
            </template>
          </v-file-input>
        </v-layout>
        <v-layout class="ma-2">
          <v-textarea
            v-model="reply.details"
            :label="$t('feedback.reply')"
            outlined
            dense
          >
          </v-textarea>
        </v-layout>
        <v-col cols="12" v-if="replyImages.length > 0">
          <p>{{ language == "en" ? "Preview Image" : "イメージのプレビュー" }}:</p>
          <template v-for="(item, i) in replyImages">
            <v-layout row wrap :key="i" class="ma-1 pa-1">
              <span>{{ item.name }}</span>
              <img :src="item.src" width="500" />
            </v-layout>
          </template>
        </v-col>
        <v-divider></v-divider>
        <v-layout class="ma-2 pa-2">
          <v-spacer />
          <v-btn dense color="primary" @click="sendReply()"
            ><v-icon> mdi-send </v-icon>
            {{ language == "en" ? "Send" : "送信" }}
          </v-btn>
        </v-layout>
      </v-card>
    </v-dialog>
    <v-progress-circular
      class="feedbackLoader"
      :width="10"
      v-show="loadingFeedback"
      indeterminate
      color="#df6464"
      :size="100"
      ><span>Loading...</span></v-progress-circular
    >
  </v-container>
</template>

<script>
import FeedbackTable from "../functions/httpRequest/ExternalConnection/FeedbackTable";
import moment from "moment";
import AWSAttachment from "../functions/Attachment";
import Swal from "sweetalert2";
import shortid from "shortid";
import { feedbackTemplate } from "../functions/emailTemplate";
import { feedbackReplyTemplate } from "../functions/emailTemplate";
import Email from "../functions/email";
import store from "../store";
import objects from "../functions/alert";
// Import Vue FilePond
import vueFilePond from "vue-filepond";
// Import FilePond styles
import "filepond/dist/filepond.min.css";
// Create component
const FilePond = vueFilePond();
export default {
  components: {
    FilePond,
  },
  data() {
    return {
      feedBackData: [], //main array for getting Data

      //For Filter the data
      search: "",
      filterRequestType: "ALL",
      filterReplyStatus: "ALL",
      optReplyStatus: ["ALL", "Replied", "Not Yet Replied"],

      //used in adding/updating new feedback
      feedbackDialog: false,
      item: {},
      attachments: [],
      images: [],
      ExistFileFeedBack: [],
      ExistAttachmentsPath: null,
      ExistReplyAttachmentsPath: null,
      isEdit: false,

      //used in reply feedback
      replyDialog: false,
      replyAttachments: [],
      reply: {},
      replyImages: [],
      ExistFileReply: [],

      hide: true, // for exist attachment
      fn: "",
      fab1: false,
      pageNumber: 1,
      itemsPerPage: 3,
      loadingFeedback: false,
      s3Keys: [], //saving a files in S3

      //Used in Email Sending
      Email: store.state.userinfo.Email,
      To: ["sd2leaders@hrd-s.com"], //actual email
      // To: ["imtrial@hrd-s.com"], //trial email
      Cc: null,
      // Cc: ["imtrial@hrd-s.com"],
      EmailFeedbackBy: [],

      //file Pond
      tempFile: [],
      files: [],
      attachment: [],
      totalSize: 0,
    }; //end return
  }, //end data

  created() {
    this.loadAllFeedback();
    this.loadingFeedback = true;
  }, //end created

  watch: {
    search(val) {
      if (val) {
        this.pageNumber = 1;
      }
    },
    filterRequestType(val) {
      if (val) {
        this.pageNumber = 1;
      }
    },
    filterReplyStatus(val) {
      if (val) {
        this.pageNumber = 1;
      }
    },
    feedbackDialog(val) {
      if (val) {
        if (!val) {
          this.item.feedBackNo = null;
          this.item.requestType = null;
          this.item.requestDetails = null;
          this.ExistFileFeedBack = null;
          this.ExistAttachmentsPath = null;
          this.tempFile = [];
        }
      }
    },
  }, //end watch

  methods: {
    removeFileUpload() {
      this.files = []; //FILES TO BE SAVE IN S3
      this.attachment = []; //ATTACHMENT NAME TO BE SAVE IN DATABASE

      for (let x in this.$refs.pond.getFiles())
        this.files.push(this.$refs.pond.getFiles()[x].file);
      // CHECK FOR ATTACHMENT
      if (this.files.length) {
        for (let i = 0; i < this.files.length; i++) {
          const attachment = this.files[i].name;
          this.attachment.push(attachment);
        }
      }
    },
    handleFileUploads() {
      if (this.attachment.length == this.tempFile.length) return; //RETURN IF ALREADY HAVE ATTACHMENT
      console.log(this.totalSize, "773");
      this.files = []; //FILES TO BE SAVE IN S3
      this.attachment = []; //ATTACHMENT NAME TO BE SAVE IN DATABASE
      for (let x in this.tempFile) {
        if (this.tempFile[x].fileSize > 10000000) {
          this.tempFile = [];
          this.files = [];
          this.attachment = [];
          this.totalSize = 0;
          this.$toast.error("File must be less than 10MB", objects.alert);
          this.$refs.pond.removeFiles();
        }
        this.files.push(this.tempFile[x].file);
      }

      // CHECK FOR ATTACHMENT
      if (this.files.length) {
        this.totalSize = 0;
        for (let i = 0; i < this.files.length; i++) {
          let totalSizeBytes = this.files[i].size;
          let totalSizeMB = (totalSizeBytes / (1024 * 1024)).toFixed(2);

          // Add the size of the current file to the total size
          this.totalSize += parseFloat(totalSizeMB);

          if (this.totalSize > 10) {
            this.tempFile = [];
            this.files = [];
            this.attachment = [];
            this.totalSize = 0;
            this.$toast.error("File must be less than 10MB", objects.alert);
            this.$refs.pond.removeFiles();
          }

          const attachment = this.files[i].name;
          this.attachment.push(attachment);

          let previewFile = URL.createObjectURL(this.files[i]);

          // PREVIEW 3 TYPES OF EXTENSION
          if (
            this.files[i].name.split(".")[1].toLowerCase() == "png" ||
            this.files[i].name.split(".")[1].toLowerCase() == "jpeg" ||
            this.files[i].name.split(".")[1].toLowerCase() == "jpg" ||
            this.files[i].name.split(".")[1].toLowerCase() == "pdf"
          ) {
            window.open(previewFile);
          }
        }
      }
    },
    //download FeedbackAttachment
    dowloadFeeback(item) {
      let Path = item.AttachmentsPath;
      let attachment = item.Attachments;
      AWSAttachment.download(Path, attachment);
    },
    //download replyAttachment
    dowloadReplyAttachment(item) {
      let Path = item.ReplyAttachmentsPath;
      let attachment = item.ReplyAttachments;
      AWSAttachment.download(Path, attachment);
    },

    addFeedback() {
      this.item = {};
      this.images = [];
      let n = this.fn + 1;
      this.item.feedBackNo = moment().format("YYYYMMDD") + "-" + n;
      this.isEdit = false;
      this.feedbackDialog = true;
      this.ExistFileFeedBack = null;
    },

    closeFeedbackDialog() {
      this.item = {};
      this.feedbackDialog = false;
      this.loadAllFeedback();
    },

    //Insert function for feedback
    async SaveFeedback() {
      console.log(this.files, "710");

      if (this.item.requestType && this.item.requestDetails) {
        // this.item.attachments = [];
        this.item.confirmationID = shortid.generate();
        this.item.feedbackBy = this.userinfo.FullName;
        this.item.feedbackByEmail = this.userinfo.Email;
        this.item.FeedbackDate = moment().format("YYYY-MM-DD");
        const date = moment().format("YYYY/MM/DD");
        let Path = `FeedBack/${date}`;

        if (this.tempFile && this.tempFile.length > 0) {
          await AWSAttachment.upload(
            null,
            "FeedBack", //Folder path
            this.files,
            (filename) => (this.item.attachments = filename),
            async (keyName) => {
              //callback after sending all attachment in s3
              this.s3Keys = keyName.map((el) => `${Path}/${el}`);
            }
          );
          // console.log(this.s3Keys,"Firsstttttttt")
          // this.item.attachments = "Meron";
          this.item.ExistAttachmentsPath = Path;
        } else {
          this.item.attachments = null;
          this.item.ExistAttachmentsPath = null;
        }
        console.log(this.item);
        FeedbackTable.Insert({
          ConfirmationID: this.item.confirmationID,
          FeedbackNo: this.item.feedBackNo,
          RequestType: this.item.requestType,
          RequestDetails: this.item.requestDetails,
          AttachmentsPath:
            this.tempFile && this.tempFile.length > 0
              ? Path
              : this.item.ExistAttachmentsPath,
          Attachments: this.item.attachments,
          FeedbackBy: this.item.feedbackBy,
          EmailFeedbackBy: this.Email,
          To: this.To,
          Cc: this.Cc,
          FeedbackDate: this.item.feedbackDate,
          ReplyDetailsDate: null,
        }).then(() => {
          this.loadAllFeedback();
          this.$toast.warning("Uploading please wait", objects.alert);
        });
        this.sendEmail(); //send email function
        this.clearSending();
        this.feedbackDialog = false;
      } else {
        Swal.fire("Please check!", "Incomplete details!", "error");
      }
    },

    //send email function
    async sendEmail() {
      const data = {
        dataLength: this.feedBackData.length,
        // Receiver: this.ToEmail,
        FeedbackNo: this.item.feedBackNo,
        RequestType: this.item.requestType,
        RequestDetails: this.item.requestDetails,
        Attachments: this.item.attachments,
      };
      console.log(data);
      var FeebackAttachment = [
        this.item.ExistAttachmentsPath + "/" + this.item.attachments,
      ];

      var To = ["sd2leaders@hrd-s.com"];

      await Email.send(
        store.state.userinfo.FullName,
        // "hrdinquiry-noreply" + "@hrd-s.com",
        // "imtrial@hrd-s.com",
        store.state.userinfo.Email,
        // "imtrial@hrd-s.com",
        To,
        null,
        await feedbackTemplate(data),
        `Feedback【${this.item.feedBackNo}】${this.item.requestType}`,
        FeebackAttachment == "null/null" ? null : FeebackAttachment
        // FeebackAttachment.length ? FeebackAttachment : null
      );
      // const result = await feedbackTemplate(data);
      // console.log(result);
    },
    clearSending() {
      this.s3Keys = [];
      this.tempFile = [];
      this.item.attachments = [];
      this.files = [];
      this.tempFile = [];
      this.$refs.pond.removeFiles();
      this.attachment = [];
      this.totalSize = 0;
      this.items = {};
      this.ExistAttachmentsPath = null;
      this.ExistFileFeedBack = [];
    },

    ResendFeedback(data) {
      this.tempFile = [];
      this.images = [];
      this.isEdit = true;
      this.item.id = data.id;
      this.ExistFileFeedBack = data.Attachments;
      this.ExistAttachmentsPath = data.AttachmentsPath;
      this.item.feedBackNo = data.FeedbackNo;
      this.item.requestType = data.RequestType;
      this.item.requestDetails = data.RequestDetails;
      this.item.attachments = data.Attachments;
      this.item.feedbackBy = data.FeedbackBy;
      this.item.feedbackDate = data.FeedbackDate;
      this.feedbackDialog = true;
    },

    //Resend Feedback
    async UpdateFeedback() {
      this.isEdit = true;
      if (this.item.requestType && this.item.requestDetails) {
        this.item.feedbackBy = this.userinfo.FullName;
        this.item.feedbackByEmail = this.userinfo.Email;
        this.item.attachments = [];
        const date = moment().format("YYYY/MM/DD");
        let Path = `FeedBack/${date}`;
        // console.log(Path, this.item.attachments[0]);

        if (this.attachments && this.attachments.length > 0) {
          await AWSAttachment.upload(
            null,
            "FeedBack", //Folder path
            this.attachments,
            (filename) => this.item.attachments.push(filename),
            async (keyName) => {
              //callback after sending all attachment in s3
              this.s3Keys = keyName.map((el) => `${Path}/${el}`);
            }
          );
        } else {
          this.item.attachments = this.ExistFileFeedBack || [];
          this.item.ExistAttachmentsPath = this.ExistAttachmentsPath;
        }

        FeedbackTable.Update({
          id: this.item.id,
          FeedbackNo: this.item.feedBackNo,
          RequestType: this.item.requestType,
          RequestDetails: this.item.requestDetails,
          Attachments: this.item.attachments.length > 0 ? this.item.attachments : null,
          AttachmentsPath: this.attachments.length > 0 ? Path : this.ExistAttachmentsPath,
          FeedbackBy: this.item.feedbackBy,
        }).then(() => {
          this.feedbackDialog = false;
          this.$toast.warning("Uploading please wait", objects.alert);
          this.loadAllFeedback();
        });
        this.sendEmail(); //send email function
      } else {
        Swal.fire("Please check!", "Incomplete details!", "error");
      }
    },

    previewFile2() {
      console.log(this.attachments);
      this.images = [];
      for (let i = 0; i < this.attachments.length; i++) {
        if (
          this.attachments[i].name.match(/.png/g) != null ||
          this.attachments[i].name.match(/.jpg/g) != null ||
          this.attachments[i].name.match(/.JPG/g) != null ||
          this.attachments[i].name.match(/.PNG/g) != null
        ) {
          let previewFile = {};
          previewFile.src = URL.createObjectURL(this.attachments[i]);
          previewFile.name = this.attachments[i].name;

          this.images.push(previewFile);
        }
      }
    },

    removeFile(data) {
      let index = -1;
      this.attachments.map((r, i) => {
        if (r.name == data) {
          index = i;
        }
      });
      this.attachments.splice(index, 1);
      this.previewFile2();
    },

    //Getting feedback data
    loadAllFeedback() {
      FeedbackTable.Get().then((res) => {
        if (res.data != "No data") {
          this.fn = res.data.length;
          this.feedBackData = res.data.map((r) => {
            if (!r.FeedbackNo) {
              let noFeedbackNo = res.data.filter((r) => {
                return !r.FeedbackNo;
              });
              for (let i = 0; i < noFeedbackNo.length; i++) {
                let date = r.FeedbackDate.replace(/-/gi, "");
                r.FeedbackNo = date + "-" + [i + 1];
              }
            }
            // r.isPreview = false;

            //  let data = []
            //  if (r.Attachments.length > 0) {
            //    r.src = [];
            //    r.Attachments.map((r2) => {
            //      if (r2.match(/.jpg/g) || r2.match(/.png/g)) {
            //        // console.log('filename',r2)
            //        // console.log(this.returnImg(r2))
            //        if (r2) {
            //          data.push(this.returnImg(r2));
            //        }
            //      }
            //    });
            //    Promise.all(data).then((source) => {
            //      // console.log(source)
            //            r.src = source;
            //          });
            //  }
            return r;
          });
          this.feedBackData = this.feedBackData.sort((a, b) => {
            return b.FeedbackNo > a.FeedbackNo ? 1 : -1;
          });
          // console.log(this.feedBackData);
        }

        this.loadingFeedback = false;
      });
    },

    //hard delete feedback
    deleteFeedback(data) {
      Swal.fire({
        title: `Do you want to delete <br> ${data.FeedbackNo} ?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: `Confirm`,
      }).then((result) => {
        if (result.value) {
          FeedbackTable.Delete(data.id).then(() => {
            // console.log(res);
            Swal.fire({
              icon: "success",
              title: "Successfully Deleted Feedback!",
              showConfirmButton: false,
              timer: 1500,
            });
            setTimeout(() => {
              this.loadAllFeedback();
            }, 500);
          });
        }
      });
    },

    openDiscussion(data) {
      this.replyImages = [];
      // console.log(data, "Laman");
      // this.replyAttachments = [];
      this.reply.id = data.id;
      this.reply.details = data.ReplyDetails;
      this.reply.replyAttachments = data.ReplyAttachments;
      this.ExistFileReply = data.ReplyAttachments;
      this.ExistReplyAttachmentsPath = data.ReplyAttachmentsPath;
      this.EmailFeedbackBy = data.EmailFeedbackBy;
      this.item.feedBackNo = data.FeedbackNo;
      this.item.requestType = data.RequestType;
      this.item.requestDetails = data.RequestDetails;
      // this.attachment = data.Attachments;
      this.replyDialog = true;
    },

    //insert or update reply
    async sendReply() {
      this.reply.date = moment().format("YYYY-MM-DD HH:MM:ss");

      // this.reply.replyAttachments = [];
      // this.reply.ExistReplyAttachmentsPath = [];
      const date = moment().format("YYYY/MM/DD");
      let Path = `FeedBackReply/${date}`;
      // console.log(Path, reply.replyAttachments[0]);

      if (this.replyAttachments && this.replyAttachments.length > 0) {
        await AWSAttachment.upload(
          null,
          "FeedBackReply", //Folder path
          this.replyAttachments,
          (filename) => (this.reply.replyAttachments = filename),
          async (keyName) => {
            //callback after sending all attachment in s3
            this.s3Keys = keyName.map((el) => `${Path}/${el}`);
          }
        );
        this.reply.ExistReplyAttachmentsPath = Path;
      } else {
        this.reply.replyAttachments = this.ExistFileReply || [];
        this.reply.ExistReplyAttachmentsPath = this.ExistReplyAttachmentsPath;
      }

      FeedbackTable.Update({
        id: this.reply.id,
        ReplyDetails: this.reply.details,
        ReplyDetailsDate: this.reply.date,
        ReplyAttachments:
          this.reply.replyAttachments.length > 0 ? this.reply.replyAttachments : null,
        ReplyAttachmentsPath:
          this.replyAttachments.length > 0 ? Path : this.ExistReplyAttachmentsPath,
      }).then(() => {
        // console.log(res);
        this.replyDialog = false;
        this.$toast.warning("Uploading please wait", objects.alert);
        this.loadAllFeedback();
      });
      this.sendEmailReply();
    },

    //send email reply
    async sendEmailReply() {
      const data = {
        dataLength: this.feedBackData.length,
        // Receiver: this.EmailFeedbackBy,
        FeedbackNo: this.item.feedBackNo,
        RequestType: this.item.requestType,
        RequestDetails: this.item.requestDetails,
        // Attachments: this.attachments,
        ReplyDetails: this.reply.details,
        ReplyAttachments: this.reply.replyAttachments,
        ReplyAttachmentsPath: this.reply.ExistReplyAttachmentsPath,
      };
      console.log(data);
      var ReplyAttachment = [
        this.reply.ExistReplyAttachmentsPath + "/" + this.reply.replyAttachments,
      ];
      var To = this.EmailFeedbackBy;
      // var SenderEmail = ["sd2leaders@hrd-s.com"];
      await Email.send(
        store.state.userinfo.FullName,
        // "hrdinquiry-noreply" + "@hrd-s.com",
        store.state.userinfo.Email,
        To,
        null,
        await feedbackReplyTemplate(data),
        `Feedback【${this.item.feedBackNo}】${this.item.requestType}`,
        ReplyAttachment == "null/" ? null : ReplyAttachment
        // ReplyAttachment.length ? ReplyAttachment  : null
      );
      // const result = await feedbackReplyTemplate(data);
      // console.log(result);
    },

    previewReplyFile() {
      this.replyImages = [];
      for (let i = 0; i < this.replyAttachments.length; i++) {
        if (
          this.replyAttachments[i].name.match(/.png/g) != null ||
          this.replyAttachments[i].name.match(/.jpg/g) != null ||
          this.replyAttachments[i].name.match(/.JPG/g) != null ||
          this.replyAttachments[i].name.match(/.PNG/g) != null
        ) {
          let previewFile = {};
          previewFile.src = URL.createObjectURL(this.replyAttachments[i]);
          previewFile.name = this.replyAttachments[i].name;

          this.replyImages.push(previewFile);
        }
      }
    },
    removeReplyFile(data) {
      let index = -1;
      this.replyAttachments.map((r, i) => {
        if (r.name == data) {
          index = i;
        }
      });
      this.replyAttachments.splice(index, 1);
      this.previewReplyFile();
    },
    changeDateFormat(val) {
      return moment(val).format("YYYY-MM-DD");
    },
  }, //end methods
  computed: {
    paginationLength() {
      return Math.ceil(this.filterFeedback.length / this.itemsPerPage);
    },
    paginatedFeedback() {
      const start = (this.pageNumber - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.filterFeedback.slice(start, end);
    },
    optRequestDetails() {
      if (!this.feedbackDialog) {
        return ["ALL", "問い合わせシステムの不具合"];
      } else {
        return ["問い合わせシステムの不具合"];
      }
    },
    filterFeedback() {
      let filterData = [];
      if (this.$route.query.FeedbackNo) {
        filterData = this.feedBackData.filter((r) => {
          let search = new RegExp(this.$route.query.FeedbackNo.toUpperCase(), "g");
          return JSON.stringify(Object.values(r)).toUpperCase().match(search);
        });
      } else {
        filterData = this.feedBackData
          .filter((r) => {
            let search = new RegExp(this.search.toUpperCase(), "g");
            return JSON.stringify(Object.values(r)).toUpperCase().match(search);
          })
          .filter((r) => {
            if (this.filterRequestType != "ALL") {
              return r.RequestType == this.filterRequestType;
            } else return r;
          })
          .filter((r) => {
            if (this.filterReplyStatus == "Replied") {
              if (typeof r.ReplyDetails == "string") {
                return r.ReplyDetails;
              }
            } else if (this.filterReplyStatus == "Not Yet Replied") {
              if (typeof r.ReplyDetails === "string") {
                return r.ReplyDetails === null || r.ReplyDetails === "";
              } else {
                return !r.ReplyDetails;
              }
            } else return r;
          });
      }

      return filterData;
    },
    language() {
      return this.$i18n.locale;
    },
  }, //end computed
}; //end export default
</script>
>

<style scoped>
.feedbackLoader {
  margin-left: 45%;
  margin-top: 15%;
}
.display {
  font-family: "Times New Roman", Times, serif;
}
.bold {
  font-size: 18px !important;
  text-align: left;
  padding-top: 5px;
  padding-left: 15px;
}
.details {
  font-size: 16px !important;
  padding-left: 15px;
}
.NA {
  color: red;
}
.actions {
  display: flex;
  justify-content: center;
  align-items: center;
}
.actions div {
  margin: 0 50px;
}
/* #box-border {
  box-sizing: border-box;
  padding: 4px;
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.65);
  border: 1px solid #d9d9d9;
  font-size: 14px;
  text-align: center;
  font-weight: bold;
} */
/* .v-speed-dial{
  display: block;
  padding: 2px;
  border: 1px solid grey;
  margin-left: 20px;
  width: calc(100% - 280px);
  background-color: #213c57;
  width: 98%;
  background-color: #EDEDED;
  text-align: right;
} */
/* #main-edit .v-speed-dial {
    position: fixed !important;
 
 } */
</style>

import axios from "axios";
import keys from "../../alert";

const baseUrl = keys.auth.BaseUrl;
const apikey = keys.auth.apiKey;

const httpRequest = {
//**************************************//
//        GET FEEDBACK TABLE           //
//************************************//
Get() {
    return Promise.resolve(axios.get(`${baseUrl}/getFeedback`, apikey));
  },
//**************************************//
//  INSERT REQUEST IN FEEDBACK TABLE   //
//************************************//
Insert(data) {
  return Promise.resolve(
    axios.post(`${baseUrl}/insertFeedback`, { data: data }, apikey)
  );
},
//**************************************//
//  UPDATE REQUEST IN FEEDBACK TABLE   //
//************************************//
Update(data) {
  return Promise.resolve(
    axios.post(`${baseUrl}/updateFeedback`,{ data: data }, apikey)
  );
},
//**************************************//
//  UPDATE REQUEST IN FEEDBACK TABLE   //
//************************************//
UpdateReply(data) {
  // console.log(data,"Reply")
  return Promise.resolve(
    axios.post(`${baseUrl}/updateReplyFeedback`,{ data: data }, apikey)
  );
},
//**************************************//
//  DELETE REQUEST IN FEEDBACK TABLE   //
//************************************//
Delete(id) {
  return Promise.resolve(
    axios.delete(
      `${keys.auth.BaseUrl}/deleteFeedback/${id}`,
      keys.auth.apiKey
    )
  );
},
};
export default httpRequest;